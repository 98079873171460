import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import 'moment/locale/ru';
// eslint-disable-next-line import/no-extraneous-dependencies
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import CloseIcon from '../../../assets/svg/icons/close';
import { IDatePicker } from './types';
import CalendarIcon from '../../../assets/svg/icons/calendar';
import ArrowLeft from '../../../assets/svg/icons/arrowLeft';
import ArrowRight from '../../../assets/svg/icons/arrowRight';
import { dateFormatNoMinute } from '../../../constants/date';
import Button from '../button';
import { ButtonSize, ButtonType } from '../button/types';
import Tooltip from '../tooltip';
import InfoIcon from '../../../assets/svg/icons/info';

const SingleDatePicker: FC<IDatePicker> = (props) => {
  const {
    title = '',
    isRequired = false,
    containerClassName = '',
    titleClassName = '',
    format = dateFormatNoMinute,
    dateValue = null,
    onChange = () => {},
    placeholder = '',
    disabled = false,
    isDisabledStyle = false,
    isError = false,
    onlyFuture = false,
    onlyThisMonth = false,
    setTodayDateWithFirstClick = false,
    type,
    errorText = '',
    errorTextClassName = '',
    textInfo,
    showClearIcon = true,
  } = props;

  const listenerRef = useRef<EventListenerOrEventListenerObject | null>(null);

  const [currentStartDate, setCurrentStartDate] = useState<Moment | null>(dateValue ? moment(dateValue) : null);

  const [isRangePickerOpen, setIsRangePickerOpen] = useState<boolean>(false);

  const handleOnChange = useCallback((data: Moment | null) => {
    setCurrentStartDate(data);
  }, []);

  const handleOnClearDatePicker = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      onChange(null);
      setCurrentStartDate(null);
    },
    [onChange]
  );

  const resetCurrentDates = useCallback(() => {
    setCurrentStartDate(dateValue ? moment(dateValue) : null);
  }, [dateValue]);

  const handleOnClickButtonCancel = useCallback(() => {
    resetCurrentDates();
    setIsRangePickerOpen(false);
  }, [resetCurrentDates]);

  const listener = useCallback(
    (event: Event) => {
      const datePickerDropdownElement = document.querySelectorAll('.single-date-picker__dropdown');
      const selectDropdownElements = document.querySelectorAll('.select__dropdown');

      if (
        Array.from(datePickerDropdownElement).reduce(
          (result, element) => result || element.contains(event.target as Node),
          false
        ) ||
        Array.from(selectDropdownElements).reduce(
          (result, element) => result || element.contains(event.target as Node),
          false
        )
      ) {
        return;
      }

      handleOnClickButtonCancel();
    },
    [handleOnClickButtonCancel]
  );

  useEffect(() => {
    if (isRangePickerOpen) {
      setCurrentStartDate(dateValue ? moment(dateValue) : onlyFuture ? moment() : null);
    }
  }, [isRangePickerOpen]);

  useEffect(() => {
    if (isRangePickerOpen) {
      listenerRef.current = listener;
      document.addEventListener('mousedown', listenerRef.current);
      document.addEventListener('touchstart', listenerRef.current);
    } else {
      if (listenerRef.current) {
        document.removeEventListener('mousedown', listenerRef.current);
        document.removeEventListener('touchstart', listenerRef.current);
      }

      listenerRef.current = null;
    }
  }, [isRangePickerOpen]);

  const disabledDateTime = useCallback(
    (current: Moment | null) => ({
      disabledHours: () =>
        onlyFuture && current && new Date(current.valueOf()).toDateString() === new Date().toDateString()
          ? [...Array(moment().hour())].map((_, index) => index)
          : [],
      disabledMinutes: () =>
        onlyFuture && current && new Date(current.valueOf()).toDateString() === new Date().toDateString()
          ? [...Array(moment().minute())].map((_, index) => index)
          : [],
    }),
    [onlyFuture]
  );

  const disabledDate = useCallback(
    (current: Moment) => {
      if (onlyThisMonth)
        return (
          new Date(moment().add(-1, 'day').valueOf()).setHours(0) >= new Date(current.valueOf()).setHours(0) ||
          new Date(moment().endOf('month').valueOf()).setHours(0) <= new Date(current.valueOf()).setHours(0)
        );
      if (onlyFuture)
        return new Date(moment().add(-1, 'day').valueOf()).setHours(0) >= new Date(current.valueOf()).setHours(0);
      return false;
    },
    [onlyFuture, onlyThisMonth]
  );

  const onClick = useCallback(() => {
    if (!disabled) {
      if (setTodayDateWithFirstClick && !dateValue) {
        onChange(moment());
      } else if (!isRangePickerOpen) {
        setIsRangePickerOpen(true);
      }
    }
  }, [dateValue, disabled, isRangePickerOpen, onChange, setTodayDateWithFirstClick]);

  const onCancel = useCallback(() => {
    setIsRangePickerOpen(false);
  }, []);

  const onAccess = useCallback(() => {
    if (currentStartDate) {
      onChange(currentStartDate);
    }
    setIsRangePickerOpen(false);
  }, [currentStartDate, onChange]);

  return (
    <div
      className={classNames('single-date-picker', containerClassName, {
        'single-date-picker__default-disabled': isDisabledStyle,
        'single-date-picker__error': isError,
      })}
    >
      {title && (
        <span className={classNames('single-date-picker__title-container')}>
          <span className={classNames('single-date-picker__title', titleClassName)}>
            {title}
            {isRequired && <span className="single-date-picker__title-required">*</span>}
          </span>
          {textInfo && (
            <Tooltip title={textInfo} placement="bottom">
              <div className="single-date-picker__title-info-icon">
                <InfoIcon />
              </div>
            </Tooltip>
          )}
        </span>
      )}
      <div className="single-date-picker__content">
        <div
          role="presentation"
          className={classNames('single-date-picker__input-field', {
            'single-date-picker__input-field_focus': isRangePickerOpen && !disabled,
            'single-date-picker__input-field_disabled': disabled,
          })}
          onClick={onClick}
        >
          {dateValue ? (
            dateValue?.format(format)
          ) : (
            <span className="single-date-picker__input-field-placeholder">{placeholder}</span>
          )}
          <div className="single-date-picker__input-field-icon-container">
            <div className="single-date-picker__input-filed-icon">
              <CalendarIcon />
            </div>
            {dateValue && !disabled && (
              <div
                role="presentation"
                className={classNames(
                  'single-date-picker__input-filed-icon single-date-picker__input-filed-icon_clear',
                  {
                    'single-date-picker__input-filed-icon_clear_show': showClearIcon,
                  }
                )}
                onClick={handleOnClearDatePicker}
              >
                <CloseIcon />
              </div>
            )}
          </div>
        </div>
        <DatePicker
          picker={type}
          inputReadOnly
          disabled={disabled}
          locale={locale}
          format={format}
          className={classNames('single-date-picker__field', {
            'single-date-picker__field_clear': !currentStartDate,
          })}
          showTime
          showToday={false}
          dropdownClassName="single-date-picker__dropdown"
          getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
          suffixIcon={<CalendarIcon />}
          placeholder={placeholder}
          value={currentStartDate ? moment(currentStartDate) : null}
          onOk={() => {}}
          disabledDate={disabledDate}
          disabledTime={disabledDateTime}
          prevIcon={<ArrowLeft />}
          nextIcon={<ArrowRight />}
          open={isRangePickerOpen}
          onSelect={handleOnChange}
          renderExtraFooter={() => (
            <div className="single-date-picker__button-container">
              <Button type={ButtonType.secondary} size={ButtonSize.small} onClick={onCancel}>
                Отмена
              </Button>
              <Button size={ButtonSize.small} onClick={onAccess}>
                Выбрать
              </Button>
            </div>
          )}
        />
      </div>
      {errorText && <span className={classNames('single-date-picker__error', errorTextClassName)}>{errorText}</span>}
    </div>
  );
};

export default SingleDatePicker;
