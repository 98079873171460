import { IProfilePermission, ISettings } from '../../../typings/profile';
import { ISystemBase } from '../../../typings/systems/base';
import { InputStatus } from '../../ui/input/types';
import { IValidHardware } from './validateButtons/types';

export interface IHardwarePage {
  system?: ISystemBase;
  systemId?: string;
  onNextTab?: () => void;
  onChangeTab?: (id: string, search?: { name: string; val: any }[]) => void;
  activeTabKey?: string;
  tabId?: string;
  nextTabId?: string;
  updateAvailableTabs?: () => void;
  isAccessGroupsCheck?: boolean;
  isWasChange?: boolean;
  setChange?: (flag: boolean) => void;
  repeatChangeTab?: () => void;
  chancelChangeTab?: () => void;
  clickedTab?: string;
  permissions?: IProfilePermission;
  nextTabIsAvailable?: boolean;
  getSystemData?: () => void;
  settings?: ISettings;
  isFiveThousandth?: boolean;
  selectedNode?: ISelectedNode | null;
  clickedNode?: ISelectedNode | null;
  invalidHardwareList?: IValidHardware[] | null;
  activeInvalidHardware?: IValidHardware | null;
  repeatSelectNode?: () => void;
  updateAfterSave?: (id: string) => Promise<void>;
}

export interface ISelectedNode {
  itemId: string;
  itemType: string;
  originalKey: string;
}

export interface IHardwareOptionValue {
  value: any;
  fieldName: string;
}

export interface IHardwareInput {
  value: any;
  status: InputStatus;
  errorText?: string;
  isRequired: boolean;
  test?: RegExp;
  defaultErrorText?: string;
}

export enum EHardwareSearchParams {
  itemId = 'itemId',
  itemType = 'itemType',
  originalKey = 'originalKey',
}
