import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
// eslint-disable-next-line import/no-extraneous-dependencies
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import ChevronDown from '../../assets/svg/icons/chevronDown';
import ToastComponent from '../toastComponent';
import { INotification, INotificationStack, stackIdToIndex } from './types';
import { renderNotificationsStack, updateNotificationsStack } from '../toastComponent/notificationsStack';
import { useApi } from '../../hooks/useApi';
import { getRequest, postRequest } from '../../api';
import {
  dispatcherPanelDisableNotificationsUrl,
  notifyConnectionIdUrl,
  notifyDisabledUrl,
  notifyWebSocketUrl,
  userSettingsUrl,
} from '../../constants/api';
import {
  ENotifyType,
  ENotifyWebSocketEvents,
  IConnectionId,
  IDisableNotificationsStatus,
  INotifyWebSocketData,
  INotifyWebSocketEvent,
} from '../../typings/notifications';
import AuthService from '../../api/authService';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { setCount } from '../../store/slices/sidebar';
import { ESidebarItemIds } from '../../typings/sidebar';
import { paths } from '../../constants/paths';
import { notificationsStack } from './config';
import { EDeviceTabsIds } from '../../pages/dispatcherPanel/item/types';
import Button from '../ui/button';
import { ButtonSize, ButtonType } from '../ui/button/types';
import { EDispatcherDeviceStatus, IDisablePushPeriod } from '../../typings/dispatcherPanel';
import { getProfileInStore } from '../../store/selectors/profile';
import { setDisabledStatus } from '../../store/slices/notifications';
import { getDisabledStatus } from '../../store/selectors/notifications';
import notificationSound from '../../assets/sounds/notification.mp3';
import { calculateNextEvent } from '../../functions';
import BellOff from '../../assets/svg/icons/bellOff';
import Bell from '../../assets/svg/icons/bell';
import VolumeIcon from '../../assets/svg/icons/volume';
import { IUSerSettings } from '../../typings/userSettings';
import VolumeSettingsModal from './volumeSettingsModal';
import VolumeOffIcon from '../../assets/svg/icons/volumeOff';
import DeviceSyncModal from './deviceSyncModal';
import { IDeviceSyncData, mockData } from './deviceSyncModal/types';

const NotificationsBlock: FC = () => {
  const [stackIsOpen, setStackIsOpen] = useState(false);

  const stacks = useRef<INotificationStack[]>([]);

  const token = AuthService.getToken();

  const socketRef = useRef<WebSocket | null>(null);

  const audioRef = useRef<HTMLAudioElement | null>(null);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const profile = useAppSelector(getProfileInStore);

  const disableData = useAppSelector(getDisabledStatus);

  const { sendRequest: getConnectionData } = useApi<IConnectionId>(postRequest);

  const { data: disableNotificationsStatus, sendRequest: getDisableNotifications } =
    useApi<IDisableNotificationsStatus>(getRequest);

  const { data: userSettings, sendRequest: getUserSettings } = useApi<IUSerSettings>(getRequest);

  const { data: disablingPush, sendRequest: getDisablingPush } = useApi<IDisablePushPeriod[]>(getRequest);

  const connectionCounter = useRef(0);

  const notifyDivRef = useRef<HTMLDivElement | null>(null);
  const userInteracted = useRef(false);
  const timerIdRef = useRef<any>(null);

  const needPlaySound = useRef<boolean>(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [deviceSyncData, setDeviceSyncData] = useState<IDeviceSyncData | null>(null);

  useEffect(() => {
    if (disablingPush && disablingPush.length) {
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current);
        timerIdRef.current = null;
      }
      if (disableData.nextEvent) {
        timerIdRef.current = setTimeout(() => {
          getDisableNotifications(dispatcherPanelDisableNotificationsUrl());
          getDisablingPush(notifyDisabledUrl());
        }, disableData.nextEvent);
      }
    }
  }, [disableData]);

  useEffect(() => {
    const handleInteraction = () => {
      userInteracted.current = true;
    };
    document.addEventListener('click', handleInteraction, true);
    return () => {
      document.removeEventListener('click', handleInteraction);
    };
  }, []);

  const setVolume = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.volume =
        userSettings?.isVolumeDisabled || userSettings?.volume === 0 ? 0 : (userSettings?.volume || 100) / 100;
    }
  }, [userSettings?.isVolumeDisabled, userSettings?.volume]);

  useEffect(() => {
    setVolume();
  }, [userSettings]);

  useEffect(() => {
    if (notifyDivRef.current) {
      const audio = new Audio(notificationSound);
      audioRef.current = audio;
      setVolume();
      notifyDivRef.current.onclick = () => audio.play();
    }
  }, [notifyDivRef.current]);

  let getSocket = (connectionToken: string) => {};

  const getConnection = useCallback(async () => {
    const result: any = await getConnectionData(notifyConnectionIdUrl(), {});
    if (result.connectionToken) getSocket(result.connectionToken);
  }, [getConnectionData]);

  const getStack = useCallback((id: string) => stacks.current.find((item) => item.id === id), []);

  const getStackAndNotify = useCallback(
    (stackId: string, notifyId: string): [INotificationStack | null, INotification | null] => {
      const foundStack = stacks.current.find((item) => item.id === stackId);
      if (foundStack) {
        const foundNotify = foundStack.notifications.find((item) => item.id === notifyId);
        if (foundNotify) {
          return [foundStack, foundNotify];
        }
      }
      return [null, null];
    },
    []
  );

  const sortStacks = useCallback(() => {
    stacks.current.sort((a, b) => (stackIdToIndex.get(a.id) || 0) - (stackIdToIndex.get(b.id) || 0));
  }, []);

  let onCloseNotify = useCallback((stackId: string, notifyId: string, removeStack: boolean) => {}, []);
  let onCloseStack = useCallback((stackId: string) => {}, []);

  const onClickNotify = useCallback(
    (stackId: string, notifyId: string, clickFromStack: boolean) => {
      const [foundStack, foundNotify] = getStackAndNotify(stackId, notifyId);
      if (foundStack && foundNotify) {
        navigate(
          `${paths.dispatcherPanel}/${foundNotify.deviceId}?tabId=${
            foundNotify.isReminder ? EDeviceTabsIds.notifications : EDeviceTabsIds.journal
          }`
        );
        if (clickFromStack) {
          onCloseNotify(stackId, notifyId, true);
        } else {
          onCloseStack(notifyId);
        }
      }
    },
    [getStackAndNotify, navigate, onCloseNotify, onCloseStack]
  );

  onCloseStack = useCallback((stackId: string) => {
    toast.dismiss(stackId);
    stacks.current = stacks.current.filter((item) => item.id !== stackId);
    if (stacks.current.length === 0) {
      setStackIsOpen(false);
    }
  }, []);

  onCloseNotify = useCallback(
    (stackId: string, notifyId: string, clickFromStack: boolean) => {
      const stack = getStack(stackId);
      if (stack && notifyId) {
        stack.notifications = stack.notifications.filter((item) => item.id !== notifyId);
        if (clickFromStack) {
          updateNotificationsStack(stackId, { data: stack, onClickNotify, onCloseStack, onCloseNotify });
        } else {
          toast.dismiss(notifyId);
        }
        if (stacks.current.length === 1 && stack.notifications.length === 0) {
          stacks.current = [];
          setStackIsOpen(false);
        }
      }
    },
    [getStack, onClickNotify, onCloseNotify, onCloseStack]
  );

  const playNotificationSound = useCallback(() => {
    if (userInteracted.current && notifyDivRef.current) {
      notifyDivRef.current.dispatchEvent(new MouseEvent('click'));
    }
    needPlaySound.current = false;
  }, []);

  const showAllNotify = useCallback(
    (open = stackIsOpen) => {
      if (open) {
        toast.dismiss();
      }
      setStackIsOpen(!open);
      if (!open) {
        sortStacks();
        setTimeout(
          () =>
            stacks.current.forEach((item) =>
              renderNotificationsStack({ data: item, onClickNotify, onCloseNotify, onCloseStack })
            ),
          500
        );
      }
    },
    [stackIsOpen, onClickNotify, onCloseNotify, onCloseStack, sortStacks]
  );

  const addEventInStack = useCallback(
    (events: INotifyWebSocketData[], isReminder = false) => {
      if (stacks.current) {
        events.forEach((event) => {
          if (!event.ignoredForUsers.includes(profile.id || '')) {
            const status = event.status ? (event.status as EDispatcherDeviceStatus) : null;
            const type = isReminder ? ENotifyType.reminder : ENotifyType.status;
            const stackId = `${type}${status ? `-${status}` : ''}`;

            const newNotify: INotification = {
              id: event.id,
              status,
              title: event.statusDisplayMessage,
              deviceId: event.deviceId,
              objectId: event.objectId,
              text: event.text,
              ignoredForUsers: event.ignoredForUsers,
              isReminder,
              stackId,
              eventId: event.eventId,
              notificationId: event.notificationId,
            };

            const foundStack = getStack(stackId);
            if (foundStack) {
              foundStack.notifications.unshift(newNotify);
              updateNotificationsStack(stackId, { data: foundStack, onClickNotify, onCloseStack, onCloseNotify });
            } else {
              const stack = {
                id: stackId,
                status,
                type,
                notifications: [newNotify],
              };
              stacks.current.push(stack);
              if (stackIsOpen) {
                renderNotificationsStack({ data: stack, onClickNotify, onCloseNotify, onCloseStack });
              }
            }
            if (!stackIsOpen) {
              showAllNotify(false);
            }
            needPlaySound.current = true;
          }
        });
      }
    },
    [getStack, stackIsOpen, onClickNotify, onCloseNotify, onCloseStack, profile.id, showAllNotify]
  );

  const performAction = useCallback(
    (data: INotifyWebSocketEvent) => {
      switch (data.target) {
        case ENotifyWebSocketEvents.connected: {
          socketRef.current?.send('{"type":1, "target":"ConnectToCompany", "arguments":[]}');
          break;
        }
        case ENotifyWebSocketEvents.errorsCount: {
          dispatch(setCount({ field: ESidebarItemIds.dispatcherPanel, value: data.arguments[0].errorsCount }));
          break;
        }
        case ENotifyWebSocketEvents.statusEvent: {
          addEventInStack(data.arguments);
          break;
        }
        case ENotifyWebSocketEvents.notification: {
          addEventInStack(data.arguments, true);
          break;
        }
        case ENotifyWebSocketEvents.deviceSync: {
          setDeviceSyncData(data.arguments[0] as any);
          break;
        }
        default: {
          break;
        }
      }
      if (needPlaySound.current) {
        playNotificationSound();
      }
    },
    [addEventInStack, dispatch, playNotificationSound]
  );

  getSocket = useCallback(
    (connectionToken: string) => {
      const link =
        process.env.REACT_APP_BASE_URL === '/'
          ? `${window.location.host}/`
          : process.env.REACT_APP_BASE_URL?.split('https://')[1] || '';

      socketRef.current = new WebSocket(
        `${notifyWebSocketUrl(link)}?${new URLSearchParams({
          access_token: token,
          id: connectionToken,
        } as any).toString()}`
      );

      socketRef.current.onopen = () => socketRef.current?.send('{"protocol":"json","version":1}');

      socketRef.current.onclose = () => {
        if (connectionCounter.current <= 2) {
          connectionCounter.current += 1;
          getConnection();
        }
      };

      socketRef.current.onmessage = (event) => {
        if (event.data) {
          try {
            const parsedData: INotifyWebSocketEvent = JSON.parse(event.data.slice(0, -1));
            if (parsedData) {
              performAction(parsedData);
              if (connectionCounter.current !== 0) {
                connectionCounter.current = 0;
              }
            }
          } catch {
            getConnection();
          }
        }
      };
    },
    [connectionCounter, getConnection, performAction, token]
  );

  useEffect(() => {
    if (disableNotificationsStatus) {
      dispatch(
        setDisabledStatus({ ...disableNotificationsStatus, nextEvent: calculateNextEvent(disablingPush || []) })
      );
    }
  }, [disableNotificationsStatus, disablingPush]);

  useEffect(() => {
    getConnection();
    getDisableNotifications(dispatcherPanelDisableNotificationsUrl());
    getDisablingPush(notifyDisabledUrl());
    getUserSettings(userSettingsUrl());
  }, []);

  const onApplySoundSettings = useCallback(() => {
    setModalIsOpen(false);
    getUserSettings(userSettingsUrl());
  }, [getUserSettings]);

  return (
    <>
      <DeviceSyncModal data={deviceSyncData} />
      <ToastComponent />
      <VolumeSettingsModal
        onOk={onApplySoundSettings}
        userSettings={userSettings}
        isOpen={modalIsOpen}
        onCancel={() => setModalIsOpen(false)}
      />
      <div ref={notifyDivRef} />
      <div role="presentation" onClick={() => setModalIsOpen(true)} className="notifications-volume">
        {userSettings?.isVolumeDisabled ? <VolumeOffIcon /> : <VolumeIcon />}
      </div>
      <Button
        className={classNames('notifications-block', {
          'notifications-block_open': stackIsOpen,
          'notifications-block_disabled': disableData?.isAllDisabled,
        })}
        type={ButtonType.outline}
        size={ButtonSize.small}
        onClick={() => showAllNotify(stackIsOpen)}
        leftIcon={disableData?.isAllDisabled || disableData?.isDisableExist ? <BellOff /> : <Bell />}
        rightIcon={<ChevronDown />}
      >
        Уведомления
      </Button>
    </>
  );
};

export default NotificationsBlock;
